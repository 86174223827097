.footer-footer {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    background-image: linear-gradient(310deg,#141727,#3a416f);
  }
  .footer-container1 {
    color: var(--dl-color-gray-white);
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-tripleunit);
    z-index: 1;
    max-width: 1320px;
    justify-content: space-between;
  }
  .footer-container2 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .footer-text1 {
    font-weight: 700;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .footer-image {
    top: 0px;
    left: auto;
    right: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    position: absolute;
    object-fit: cover;
  }
  @media(max-width: 991px) {
    .footer-container1 {
      padding: var(--dl-space-space-doubleunit);
    }
  }
  @media(max-width: 767px) {
    .footer-container1 {
      flex-direction: column;
    }
    .footer-container2 {
      align-items: center;
      margin-bottom: var(--dl-space-space-doubleunit);
    }
  }
  