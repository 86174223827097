.profile-container10 {
    width: 100%;
    height: auto;
    display: flex;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
  .profile-profile {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    max-width: 1320px;
    margin-top: var(--dl-space-space-sixunits);
    align-items: flex-start;
    padding-top: var(--dl-space-space-sixunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .profile-image1 {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-radius75);
  }
  .profile-container11 {
    flex: 2;
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-sixunits);
    margin-right: var(--dl-space-space-sixunits);
    flex-direction: column;
  }
  .profile-container12 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
    justify-content: space-between;
  }
  .profile-text10 {
    color: var(--dl-color-secondary-700);
    font-size: 1.5rem;
    font-style: normal;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .profile-container13 {
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
  }
  .profile-text11 {
    margin-right: var(--dl-space-space-unit);
  }
  .profile-text12 {
    color: var(--dl-color-secondary-700);
    font-style: normal;
    font-weight: 600;
  }
  .profile-text14 {
    margin-right: var(--dl-space-space-unit);
  }
  .profile-text15 {
    color: var(--dl-color-secondary-700);
    font-style: normal;
    font-weight: 600;
  }
  .profile-text18 {
    color: var(--dl-color-secondary-700);
    font-style: normal;
    font-weight: 600;
  }
  .profile-blog-posts {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    max-width: 1320px;
    align-items: flex-start;
    padding-top: var(--dl-space-space-sixunits);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .profile-text21 {
    color: var(--dl-color-secondary-500);
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .profile-container14 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
  }
  .profile-container15 {
    flex: 1;
    margin: var(--dl-space-space-unit);
    display: flex;
    align-items: stretch;
    flex-direction: row;
    justify-content: flex-start;
  }
  .profile-card {
    flex: 1;
    width: 255px;
    display: flex;
    padding: var(--dl-space-space-unitandahalfunit);
    position: relative;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius1);
    flex-direction: column;
    background-size: cover;
    background-image: url("https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/seaside.jpg");
  }
  .profile-container16 {
    display: flex;
    z-index: 2;
    align-items: flex-start;
    flex-direction: column;
  }
  .profile-text24 {
    color: var(--dl-color-gray-white);
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .profile-text25 {
    color: var(--dl-color-gray-white);
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .profile-text26 {
    display: inline;
    font-weight: 400;
    white-space: normal;
  }
  .profile-container17 {
    display: flex;
    position: relative;
  }
  .profile-button {
    color: var(--dl-color-blue-500);
    display: flex;
    padding-top: var(--dl-space-space-halfunit);
    border-width: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: row;
    padding-bottom: var(--dl-space-space-halfunit);
    background-color: transparent;
  }
  .profile-text27 {
    color: var(--dl-color-gray-white);
    margin-right: var(--dl-space-space-halfunit);
  }
  .profile-icon10 {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
  }
  .profile-container18 {
    top: auto;
    flex: 0 0 auto;
    left: 0px;
    right: auto;
    width: 100%;
    border: 2px dashed rgba(120, 120, 120, 0.4);
    bottom: 0px;
    height: 100%;
    display: flex;
    position: absolute;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius1);
    flex-direction: column;
    background-color: rgba(0,0,0,.4);
  }
  .profile-contact {
    width: 100%;
    max-width: 1320px;
    padding-top: var(--dl-space-space-sixunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .profile-container19 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
    align-items: stretch;
    border-radius: var(--dl-radius-radius-radius1);
    flex-direction: row;
    background-color: var(--dl-color-gray-white);
  }
  .profile-form1 {
    flex: 5;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .profile-text28 {
    color: var(--dl-color-secondary-500);
    margin-bottom: var(--dl-space-space-unit);
  }
  .profile-text30 {
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .profile-form2 {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .profile-text31 {
    color: var(--dl-color-secondary-500);
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .profile-textinput1 {
    width: 100%;
    height: 40px;
    transition: 0.3s;
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-secondary-100);
    padding-left: var(--dl-space-space-triplequarterunit);
    border-radius: var(--dl-radius-radius-radius5);
    padding-right: var(--dl-space-space-triplequarterunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .profile-textinput1:focus {
    outline: 0;
    box-shadow: 0 0 0 2px #e9aede;
    border-color: var(--dl-color-pink-200);
  }
  .profile-text32 {
    color: var(--dl-color-secondary-500);
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .profile-textinput2 {
    width: 100%;
    height: 40px;
    transition: 0.3s;
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-secondary-100);
    padding-left: var(--dl-space-space-triplequarterunit);
    border-radius: var(--dl-radius-radius-radius5);
    padding-right: var(--dl-space-space-triplequarterunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .profile-textinput2:focus {
    outline: 0;
    box-shadow: 0 0 0 2px #e9aede;
    border-color: var(--dl-color-pink-200);
  }
  .profile-text33 {
    color: var(--dl-color-secondary-500);
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .profile-textarea {
    width: 100%;
    transition: 0.3s;
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-secondary-100);
    padding-left: var(--dl-space-space-triplequarterunit);
    border-radius: var(--dl-radius-radius-radius5);
    padding-right: var(--dl-space-space-triplequarterunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .profile-textarea:focus {
    outline: 0;
    box-shadow: 0 0 0 2px #e9aede;
    border-color: var(--dl-color-pink-200);
  }
  .profile-container20 {
    display: flex;
    align-self: flex-end;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
  }
  .profile-container21 {
    display: flex;
  }
  .profile-info {
    flex: 4;
    color: var(--dl-color-gray-white);
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    padding-left: var(--dl-space-space-sixunits);
    border-radius: 0px;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    background-image: url("/curved5-1400w.jpg");
    border-top-right-radius: var(--dl-radius-radius-radius1);
    border-bottom-right-radius: var(--dl-radius-radius-radius1);
  }
  .profile-container22 {
    display: flex;
    padding: var(--dl-space-space-fourunits);
    z-index: 1;
    align-items: flex-start;
    flex-direction: column;
  }
  .profile-text37 {
    opacity: 0.8;
  }
  .profile-container23 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-doubleunit);
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .profile-container24 {
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
  }
  .profile-icon12 {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .profile-link1 {
    opacity: 0.8;
    text-decoration: none;
  }
  .profile-container25 {
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
  }
  .profile-icon14 {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .profile-link2 {
    opacity: 0.8;
    text-decoration: none;
  }
  .profile-container26 {
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
  }
  .profile-icon16 {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .profile-text38 {
    opacity: 0.8;
  }
  .profile-container27 {
    flex: 0 0 auto;
    width: 200px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .profile-icon18 {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-doubleunit);
  }
  .profile-icon20 {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-doubleunit);
  }
  .profile-icon22 {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-doubleunit);
  }
  .profile-icon24 {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-doubleunit);
  }
  .profile-image2 {
    top: 0px;
    left: -1px;
    right: auto;
    width: 100px;
    z-index: 2;
    position: absolute;
    object-fit: cover;
  }
  .profile-container28 {
    top: auto;
    left: auto;
    right: 0px;
    width: 100%;
    bottom: 0px;
    height: 100%;
    opacity: 0.9;
    position: absolute;
    background-image: linear-gradient(310deg,#2152ff,#21d4fd);
  }
  @media(max-width: 991px) {
    .profile-container11 {
      margin-left: var(--dl-space-space-tripleunit);
      margin-right: var(--dl-space-space-tripleunit);
    }
    .profile-container15 {
      width: 100%;
    }
    .profile-card {
      width: 100%;
    }
    .profile-container19 {
      flex-direction: column;
    }
    .profile-info {
      padding-left: 0px;
      border-top-right-radius: 0;
      border-bottom-left-radius: var(--dl-radius-radius-radius1);
    }
    .profile-image2 {
      display: none;
    }
  }
  @media(max-width: 767px) {
    .profile-profile {
      padding-top: var(--dl-space-space-fourunits);
      padding-left: var(--dl-space-space-doubleunit);
      padding-right: var(--dl-space-space-doubleunit);
      flex-direction: column;
      padding-bottom: var(--dl-space-space-fourunits);
    }
    .profile-container11 {
      margin-left: 0px;
      margin-right: 0px;
    }
    .profile-container12 {
      margin-top: var(--dl-space-space-unit);
      margin-bottom: var(--dl-space-space-unit);
    }
    .profile-text10 {
      margin-top: var(--dl-space-space-unit);
    }
    .profile-blog-posts {
      padding-top: var(--dl-space-space-doubleunit);
      padding-left: var(--dl-space-space-doubleunit);
      padding-right: var(--dl-space-space-doubleunit);
      padding-bottom: var(--dl-space-space-doubleunit);
    }
    .profile-container15 {
      margin-top: var(--dl-space-space-doubleunit);
    }
    .profile-contact {
      padding-top: var(--dl-space-space-doubleunit);
      padding-left: var(--dl-space-space-doubleunit);
      padding-right: var(--dl-space-space-doubleunit);
      padding-bottom: var(--dl-space-space-doubleunit);
    }
    .profile-form1 {
      padding-top: var(--dl-space-space-doubleunit);
    }
  }
  