.header-header {
    top: 0px;
    left: auto;
    right: auto;
    width: 100%;
    bottom: auto;
    display: flex;
    z-index: 3;
    position: fixed;
    max-width: 1320px;
    padding-left: var(--dl-space-space-unitandahalfunit);
    padding-right: var(--dl-space-space-unitandahalfunit);
    justify-content: center;
  }
  .header-nav {
    flex: 0 0 auto;
    color: var(--dl-color-secondary-600);
    width: 100%;
    height: 60px;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 .25rem .375rem -.0625rem hsla(0,0%,8%,.12),0 .125rem .25rem -.0625rem hsla(0,0%,8%,.07)!important;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unitandahalfunit);
    border-radius: var(--dl-radius-radius-radius40);
    padding-right: var(--dl-space-space-unitandahalfunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-halfunit);
    backdrop-filter: saturate(200%) blur(30px);
    justify-content: space-between;
    background-color: hsla(0,0%,100%,.8)!important;
  }
  .header-container1 {
    color: var(--dl-color-gray-black);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .header-navlink1 {
    color: var(--dl-color-secondary-700);
    font-weight: 600;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    text-decoration: none;
  }
  .header-menu1 {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .header-navlink2 {
    margin-top: var(--dl-space-space-halfunit);
    transition: 0.3s;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
    text-decoration: none;
  }
  .header-navlink2:hover {
    color: var(--dl-color-gray-500);
  }
  .header-navlink3 {
    margin-top: var(--dl-space-space-halfunit);
    transition: 0.3s;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
    text-decoration: none;
  }
  .header-navlink3:hover {
    color: var(--dl-color-gray-500);
  }
  .header-navlink4 {
    margin-top: var(--dl-space-space-halfunit);
    transition: 0.3s;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
    text-decoration: none;
  }
  .header-navlink4:hover {
    color: var(--dl-color-gray-500);
  }
  .header-container2 {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .header-container3 {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .header-burger-menu {
    display: none;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .header-icon1 {
    width: 24px;
    height: 24px;
    margin-left: var(--dl-space-space-unit);
  }
  .header-mobile-menu {
    top: 0px;
    flex: 0 0 auto;
    left: 0px;
    width: 100%;
    height: 100vh;
    display: none;
    padding: var(--dl-space-space-unitandahalfunit);
    z-index: 100;
    position: absolute;
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--dl-color-gray-white);
  }
  .header-top {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .header-navlink5 {
    color: var(--dl-color-secondary-700);
    font-weight: 600;
    text-decoration: none;
  }
  .header-close-menu {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .header-icon3 {
    width: 24px;
    height: 24px;
  }
  .header-mid {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .header-menu2 {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .header-navlink6 {
    transition: 0.3s;
    margin-bottom: var(--dl-space-space-halfunit);
    text-decoration: none;
  }
  .header-navlink6:hover {
    color: var(--dl-color-gray-500);
  }
  .header-navlink7 {
    transition: 0.3s;
    margin-bottom: var(--dl-space-space-halfunit);
    text-decoration: none;
  }
  .header-navlink7:hover {
    color: var(--dl-color-gray-500);
  }
  .header-navlink8 {
    transition: 0.3s;
    text-decoration: none;
  }
  .header-navlink8:hover {
    color: var(--dl-color-gray-500);
  }
  .header-bot {
    flex: 0 0 auto;
    display: flex;
    margin-top: auto;
    flex-direction: column;
  }
  @media(max-width: 991px) {
    .header-nav {
      max-width: 960px;
    }
    .header-menu1 {
      display: none;
    }
  }
  @media(max-width: 767px) {
    .header-burger-menu {
      display: flex;
    }
  }
  @media(max-width: 479px) {
    .header-container3 {
      display: none;
    }
  }
  