.home-container10 {
    width: 100%;
    height: auto;
    display: flex;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .home-hero {
    width: 100%;
    display: flex;
    max-width: 1320px;
    padding-left: var(--dl-space-space-unitandahalfunit);
    padding-right: var(--dl-space-space-unitandahalfunit);
    justify-content: center;
  }
  .home-container11 {
    width: 100%;
    display: flex;
    position: relative;
    max-width: 1320px;
    min-height: 85vh;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .home-card {
    flex: 0 0 auto;
    width: 60%;
    display: flex;
    padding: var(--dl-space-space-tripleunit);
    z-index: 1;
    box-shadow: 0 8px 26px -4px hsla(0,0%,8%,.15),0 8px 9px -5px hsla(0,0%,8%,.06)!important;
    margin-top: var(--dl-space-space-tripleunit);
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius1);
    flex-direction: column;
    backdrop-filter: saturate(200%) blur(30px);
    background-color: hsla(0,0%,100%,.8);
  }
  .home-text10 {
    text-align: center;
    background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .home-text11 {
    color: var(--dl-color-secondary-700);
    text-align: center;
    margin-bottom: var(--dl-space-space-unitandahalfunit);
  }
  .home-text12 {
    color: var(--dl-color-secondary-600);
    margin-right: var(--dl-space-space-tripleunit);
    margin-bottom: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-tripleunit);
  }
  .home-container12 {
    display: flex;
    margin-top: var(--dl-space-space-unitandahalfunit);
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
  }
  .home-container13 {
    display: flex;
    align-items: flex-start;
    margin-right: var(--dl-space-space-unit);
    flex-direction: row;
  }
  .home-image1 {
    top: 0px;
    left: auto;
    right: 0px;
    width: 50%;
    bottom: 0px;
    height: 80vh;
    position: absolute;
    object-fit: cover;
    object-position: right;
    border-bottom-left-radius: 10rem;
  }
  .home-features {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1320px;
    align-items: flex-start;
    padding-top: var(--dl-space-space-sixunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .home-container14 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    max-width: 1320px;
    align-items: center;
    padding-top: var(--dl-space-space-sixunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .home-container15 {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
  }
  .home-text13 {
    margin-bottom: var(--dl-space-space-halfunit);
    background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .home-text14 {
    color: rgb(103, 116, 142);
    max-width: 600px;
    text-align: center;
    font-weight: 400;
    margin-bottom: var(--dl-space-space-halfunit);
    background-color: rgb(255, 255, 255);
  }
  .home-container16 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-tripleunit);
    align-items: flex-start;
    margin-left: var(--dl-space-space-unitandahalfunit);
    margin-right: var(--dl-space-space-unitandahalfunit);
    flex-direction: row;
  }
  .home-container17 {
    flex: 0 0 auto;
    width: 50%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-image2 {
    width: 100%;
    box-shadow: 0 8px 26px -4px hsla(0,0%,8%,.15),0 8px 9px -5px hsla(0,0%,8%,.06)!important;
    object-fit: cover;
    margin-left: 3rem;
    margin-right: -3rem;
    border-radius: var(--dl-radius-radius-radius75);
  }
  .home-container18 {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-image3 {
    top: 0px;
    left: auto;
    width: 90%;
    bottom: auto;
    display: block;
    position: absolute;
    box-shadow: 0 8px 26px -4px hsla(0,0%,8%,.15),0 8px 9px -5px hsla(0,0%,8%,.06)!important;
    margin-top: -1.5rem;
    object-fit: cover;
    margin-left: 2rem;
    border-radius: var(--dl-radius-radius-radius75);
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-container19 {
    flex: 0 0 auto;
    width: 40%;
    display: flex;
    position: relative;
    align-items: flex-start;
    padding-left: var(--dl-space-space-triplequarterunit);
    padding-right: var(--dl-space-space-triplequarterunit);
    flex-direction: column;
  }
  .home-image4 {
    width: 100%;
    box-shadow: 0 8px 26px -4px hsla(0,0%,8%,.15),0 8px 9px -5px hsla(0,0%,8%,.06)!important;
    margin-top: 8rem;
    object-fit: cover;
    margin-left: -1.5rem;
    border-radius: var(--dl-radius-radius-radius75);
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-container20 {
    width: 90%;
    display: flex;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-testimonials {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    background-image: linear-gradient(310deg,#7928ca,#ff0080);
  }
  .home-bottom-wave-image {
    top: auto;
    left: auto;
    right: 0px;
    width: 100%;
    bottom: -5px;
    position: absolute;
    object-fit: cover;
  }
  .home-image5 {
    top: auto;
    left: 0px;
    right: 0px;
    width: 100%;
    bottom: auto;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }
  .home-top-wave-image {
    top: -5px;
    left: auto;
    right: 0px;
    width: 100%;
    bottom: auto;
    position: absolute;
    object-fit: cover;
  }
  .home-contaier {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    max-width: 1320px;
    align-items: center;
    padding-top: var(--dl-space-space-sixunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .home-container21 {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .home-container22 {
    flex: 0 0 auto;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    border-radius: var(--dl-radius-radius-radius75);
    flex-direction: column;
    justify-content: center;
    background-image: linear-gradient(310deg,#7928ca,#ff0080);
  }
  .home-icon1 {
    fill: var(--dl-color-gray-800);
    width: 24px;
    height: 24px;
  }
  .home-text17 {
    margin-top: var(--dl-space-space-unitandahalfunit);
    background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .home-container23 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-tripleunit);
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .home-container24 {
    display: flex;
    position: relative;
    box-shadow: 0 8px 26px -4px hsla(0,0%,8%,.15),0 8px 9px -5px hsla(0,0%,8%,.06)!important;
    transition: 0.3s;
    align-items: center;
    padding-top: var(--dl-space-space-sixunits);
    padding-left: var(--dl-space-space-sixunits);
    border-radius: var(--dl-radius-radius-radius1);
    padding-right: var(--dl-space-space-sixunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-doubleunit);
    background-size: cover;
    justify-content: center;
    background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/team-working.jpg');
    background-position: center;
  }
  .home-container24:hover {
    transform: scale(1.04);
  }
  .home-container25 {
    top: 0px;
    left: 0px;
    right: auto;
    width: 100%;
    bottom: auto;
    height: 100%;
    position: absolute;
    background: rgba(0,0,0,.4);
    border-radius: var(--dl-radius-radius-radius1);
  }
  .home-container26 {
    display: flex;
    z-index: 1;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-icon3 {
    fill: var(--dl-color-gray-900);
    width: 30px;
    height: 30px;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-text18 {
    color: var(--dl-color-gray-white);
    max-width: 200px;
    text-align: center;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-container27 {
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .home-container28 {
    flex: 0 0 auto;
    display: flex;
    padding: 1rem;
    max-width: 500px;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-text19 {
    font-weight: 600;
  }
  .home-divider {
    flex: 0 0 auto;
    width: 90%;
    height: 1px;
    display: flex;
    opacity: 0.25;
    margin-top: 4rem;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius75);
    margin-bottom: 4rem;
    flex-direction: column;
    background-color: var(--dl-color-gray-700);
  }
  @media(max-width: 991px) {
    .home-hero {
      padding-left: var(--dl-space-space-tripleunit);
      padding-right: var(--dl-space-space-tripleunit);
    }
    .home-container11 {
      max-width: 960px;
    }
    .home-card {
      width: 100%;
    }
    .home-features {
      max-width: 960px;
    }
    .home-container14 {
      max-width: 960px;
      padding-left: var(--dl-space-space-doubleunit);
      padding-right: var(--dl-space-space-doubleunit);
    }
    .home-container15 {
      max-width: 80%;
    }
    .home-text14 {
      text-align: center;
    }
    .home-contaier {
      padding-left: var(--dl-space-space-doubleunit);
      padding-right: var(--dl-space-space-doubleunit);
    }
    .home-container27 {
      width: 45%;
      margin-left: var(--dl-space-space-unit);
    }
  }
  @media(max-width: 767px) {
    .home-card {
      align-items: center;
    }
    .home-text12 {
      text-align: center;
      margin-right: 0px;
      padding-right: 0px;
    }
    .home-image1 {
      display: none;
    }
    .home-features {
      max-width: 720px;
      padding-top: var(--dl-space-space-doubleunit);
      padding-left: var(--dl-space-space-unitandahalfunit);
      padding-right: var(--dl-space-space-unitandahalfunit);
      padding-bottom: var(--dl-space-space-doubleunit);
    }
    .home-container14 {
      max-width: 720px;
    }
    .home-container16 {
      align-items: center;
      flex-direction: column;
    }
    .home-container17 {
      width: 80%;
    }
    .home-image2 {
      margin-left: 0px;
      margin-right: 0px;
    }
    .home-image3 {
      display: none;
    }
    .home-container19 {
      width: 80%;
    }
    .home-image4 {
      display: none;
    }
    .home-container21 {
      width: 80%;
    }
    .home-container23 {
      flex-direction: column;
    }
    .home-container27 {
      width: 80%;
      margin-top: var(--dl-space-space-fourunits);
      align-items: center;
      margin-left: 0px;
    }
    .home-divider {
      margin-top: var(--dl-space-space-doubleunit);
      margin-bottom: var(--dl-space-space-doubleunit);
    }
  }
  @media(max-width: 479px) {
    .home-card {
      padding: var(--dl-space-space-unit);
    }
    .home-container12 {
      align-items: center;
      flex-direction: column;
    }
    .home-container13 {
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-features {
      width: 100%;
    }
  }
  