.header-full-header {
    width: 100%;
    height: 60px;
    display: flex;
    z-index: 3;
    box-shadow: 0 .25rem .375rem -.0625rem hsla(0,0%,8%,.12),0 .125rem .25rem -.0625rem hsla(0,0%,8%,.07)!important;
    padding-left: var(--dl-space-space-unitandahalfunit);
    padding-right: var(--dl-space-space-unitandahalfunit);
    justify-content: center;
  }
  .header-full-nav {
    flex: 0 0 auto;
    color: var(--dl-color-secondary-600);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1320px;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unitandahalfunit);
    padding-right: var(--dl-space-space-unitandahalfunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
  }
  .header-full-container1 {
    color: var(--dl-color-gray-black);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .header-full-navlink1 {
    font-weight: 600;
    margin-right: var(--dl-space-space-unit);
    text-decoration: none;
  }
  .header-full-menu {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .header-full-navlink2 {
    margin-top: var(--dl-space-space-halfunit);
    transition: 0.3s;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
    text-decoration: none;
  }
  .header-full-navlink2:hover {
    color: var(--dl-color-gray-500);
  }
  .header-full-navlink3 {
    margin-top: var(--dl-space-space-halfunit);
    transition: 0.3s;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
    text-decoration: none;
  }
  .header-full-navlink3:hover {
    color: var(--dl-color-gray-500);
  }
  .header-full-navlink4 {
    margin-top: var(--dl-space-space-halfunit);
    transition: 0.3s;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
    text-decoration: none;
  }
  .header-full-navlink4:hover {
    color: var(--dl-color-gray-500);
  }
  .header-full-container2 {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .header-full-container3 {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .header-full-icon1 {
    width: 24px;
    height: 24px;
    display: none;
    margin-left: var(--dl-space-space-unit);
  }
  @media(max-width: 991px) {
    .header-full-nav {
      max-width: 960px;
    }
    .header-full-navlink1 {
      color: var(--dl-color-secondary-700);
    }
    .header-full-menu {
      display: none;
    }
    .header-full-icon1 {
      display: flex;
    }
  }
  @media(max-width: 479px) {
    .header-full-container3 {
      display: none;
    }
  }
  